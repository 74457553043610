import Styles from '../Styles/grid.module.scss';
import React from 'react';
import { NavLink } from "react-router-dom";
import Popup from '../Components/popup';
import Footer from '../Components/footer';
import vid1 from '../Assets/vid1.mp4';
import vid2 from '../Assets/vid2.mp4';
import vid3 from '../Assets/vid3.mp4';
import bitcoinQR from '../Assets/bitcoinQR.jpeg';
import ethereumQR from '../Assets/ethereumQR.jpeg';
import img4 from '../Assets/img4.jpg';

export default function Grid() {
    const [showDiscounts, setShowDiscounts] = React.useState(false)
    const onClickDiscounts = () => setShowDiscounts(!showDiscounts)

    const [showSocials, setShowSocials] = React.useState(false)
    const onClickSocials = () => setShowSocials(!showSocials)

    const [showCrypto, setShowCrypto] = React.useState(false)
    const onClickCrypto = () => setShowCrypto(!showCrypto)

    const [showWishlist, setShowWishlist] = React.useState(false)
    const onClickWishlist = () => setShowWishlist(!showWishlist)

    return (
        <>
            {showDiscounts ?
                <Popup>
                    <button className={Styles.popupClose} onClick={onClickDiscounts}>
                        <i className="fas fa-times" />
                    </button>
                    <div className={Styles.popupContent}>
                        <h2>FREE GIVEAWAY</h2>
                        <p className={Styles.textAlt2}>
                            Win free luxury sex toys, used panties, pantyhose, and more–it’s easy, join <a
                                id={Styles.popupLinkAlt}
                                href="https://onlyfans.com/tashablack"
                                target="_blank" rel="noreferrer"
                                title="Onlyfans - @tashablack">onlyfans.com/tashablack</a>{' '}
                            for your chance to win this month’s free giveaway
                        </p>
                        <img src={img4} />
                        <a className={Styles.popupLink} href="https://oxy-shop.com?aff=266" target="_blank" rel="noreferrer" title="Oxy shop">oxy-shop.com</a>
                        <p className={Styles.text}>get 5% Off Sex Toys... make an account + use code MYTASHA</p>
                        <a className={Styles.popupLink} href="https://www.tantaly.com" target="_blank" rel="noreferrer" title="Tantaly">tantaly.com</a>
                        <p className={Styles.text}>get 10% Off Sex Dolls use code TASHA</p>
                    </div>
                </Popup> : null}

            {showSocials ?
                <Popup>
                    <button className={Styles.popupClose} onClick={onClickSocials}>
                        <i className="fas fa-times" />
                    </button>
                    <div className={Styles.popupContentalt}>
                        <a className={Styles.popupLink} href="https://www.instagram.com/thetashablack" target="_blank" rel="noreferrer" title="Instagram - @thetashablack">
                            <i className="fab fa-instagram" />
                            <p className={Styles.text}>Instagram</p>
                        </a>
                        <a className={Styles.popupLink} href="https://www.facebook.com/tasha.black.37454/" target="_blank" rel="noreferrer" title="Facebook - @tasha.black.37454">
                            <i className="fab fa-facebook-square" />
                            <p className={Styles.text}>Facebook</p>
                        </a>
                        <a className={Styles.popupLink} href="https://twitter.com/celebrityofsin" target="_blank" rel="noreferrer" title="Twitter - @celebrityofsin">
                            <i className="fab fa-twitter-square" />
                            <p className={Styles.text}>Twitter</p>
                        </a>
                        <a className={Styles.popupLink} href="https://www.reddit.com/user/thetashablack" target="_blank" rel="noreferrer" title="Reddit - @thetashablack">
                            <i className="fab fa-reddit-square" />
                            <p className={Styles.text}>Reddit</p>
                        </a>
                    </div>
                </Popup> : null}

            {showCrypto ?
                <Popup>
                    <button className={Styles.popupClose} onClick={onClickCrypto}>
                        <i className="fas fa-times" />
                    </button>
                    <div className={Styles.popupContentAlt}>
                        <div className={Styles.popupSectionAlt}>
                            <i className="fa-brands fa-bitcoin" />
                            <img src={bitcoinQR} />
                            <p>bc1qsqluqqj6ek3m2695p3nf9gjfhfmve602c0unra</p>
                        </div>
                        <div className={Styles.popupSectionAlt}>
                            <i className="fa-brands fa-ethereum" />
                            <img src={ethereumQR} />
                            <p>0x4211c158AB39d8A5E50a9c17D9c79Bb38C6B5a99</p>
                        </div>
                    </div>
                </Popup> : null}

            {showWishlist ?
                <Popup>
                    <button className={Styles.popupClose} onClick={onClickWishlist}>
                        <i className="fas fa-times" />
                    </button>
                    <div className={Styles.popupContent}>
                        <a className={Styles.popupLink} href="https://www.thingstogetme.com/372843accd88" target="_blank" rel="noreferrer" title="Thingstogetme - Tasha Black">Open Wishlist</a>
                        <p className={Styles.textAlt2}>With every item you send, you’ll receive a free shoutout, free custom pics, and/or free 5 minute custom video wearing your gift!</p>
                    </div>
                </Popup> : null}

            <div className={Styles.container}>
                <NavLink className={Styles.one} to="/Shop">
                    <div className={Styles.overlay}></div>
                    <div className={Styles.text}>Shop Harnesses</div>
                </NavLink>
                <a className={Styles.two} href="https://onlyfans.com/tashablack" target="_blank" rel="noreferrer" title="Onlyfans - @tashablack">
                    <video src={vid1} loop autoPlay muted playsInline />
                    <div className={Styles.overlay}></div>
                    <div className={Styles.text}>Onlyfans</div>
                </a>
                <div className={Styles.three}>
                    <a className={Styles.link} onClick={onClickCrypto}>Crypto</a>
                    <a className={Styles.link} href="https://venmo.com/BKStores" target="_blank" rel="noreferrer" title="Venmo - @BKStores">Venmo</a>
                    <a className={Styles.link} href="https://cash.app/$BKStores" target="_blank" rel="noreferrer" title="CashApp - $BKStores">CashApp</a>
                </div>
                <a className={Styles.four} href="https://www.youtube.com/channel/UCQ8eRmAh_FjKr1PuDTGXUfg" target="_blank" rel="noreferrer" title="YouTube - Tasha Black">
                    <video src={vid3} loop autoPlay muted playsInline />
                    <div className={Styles.overlay}></div>
                    <div className={Styles.text}>YouTube</div>
                </a>
                <a className={Styles.five} href="https://iwantclips.com/store/704057/TashaBlack" target="_blank" rel="noreferrer" title="IWantClips - Tasha Black">
                    <video src={vid2} loop autoPlay muted playsInline />
                    <div className={Styles.overlay}></div>
                    <div className={Styles.text}>IWantClips</div>
                </a>
                <a className={Styles.six} href="https://www.niteflirt.com/listings/show/11844522-Bi-Pegging-Cuckold-Chasity-Sissy-Pantyhose" target="_blank" rel="noreferrer" title="Niteflirt - Tasha Black">
                    <div className={Styles.text}>Call Me</div>
                </a>
                <button className={Styles.seven} onClick={onClickWishlist}>
                    <div id={Styles.hide} className={Styles.text}>Wishlist</div>
                </button>
                <button className={Styles.eight} onClick={onClickDiscounts}>
                    <div className={Styles.overlay}></div>
                    <div className={Styles.text}>Discounts</div>
                </button>
                <button className={Styles.nine} onClick={onClickSocials}>
                    <div className={Styles.overlay}></div>
                    <div className={Styles.text}>Follow Me</div>
                </button>
            </div>
            <Footer />
        </>
    )
}