import Styles from "../Styles/footer.module.scss";
import instagram from '../Assets/insta.png';

export default function Footer() {
    return (
        <footer className={Styles.footer}>
            <a href="https://github.com/AlexBeesley" target="_blank" rel="noopener noreferrer">
                &copy; 2022 developed by Alex Beesley
            </a>
        </footer>
    )
}