
import { useEffect, useState } from "react";
import Styles from "../Styles/ageconsent.module.scss";

const body = document.body
body.style.overflow = 'hidden';

export default function AgeConsent() {
    let data = window.localStorage.getItem('AGE_KEY')
    if (data !== null) {
        data = JSON.parse(data);
    }

    const [age, setCookie] = useState(
        () => data || false
    );

    useEffect(() => {
        window.localStorage.setItem('AGE_KEY', JSON.stringify(age));
    }, [age]);

    console.log("age:", age);

    if (!age) {
        return (
            <div id={Styles.ageConsent}>
                <div className={Styles.overlay} />
                <div className={Styles.ageConsent}>
                    <p className={Styles.emoji}>🔞</p>
                    <p className={Styles.text}>To enter this website you must be over 18 years old.</p>
                    <button className={Styles.btn} onClick={() => consented()}>Confirm</button>
                </div>
            </div>
        )
    }

    else {
        body.style.overflow = 'auto';
        return null;
    }

    function consented() {
        console.log("consented to use of ages.");
        setCookie(!age)
        console.log("age:", age);
        const root = document.getElementById(Styles.ageConsent);
        body.style.overflow = 'auto';
        if (root) {
            root.style.display = 'none';
        }
    }
}