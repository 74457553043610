import Styles from '../Styles/shop.module.scss'
import { NavLink } from 'react-router-dom';

export default function Shop() {
    let root = document.documentElement;
    root.style.setProperty('--Primary', '#fff');

    return (
        <div className={Styles.container}>
            <NavLink className={Styles.backBtn} to="/">
                <i className="fas fa-times"></i>
            </NavLink>
            <div className={Styles.background} />
            <div className={Styles.content}>
                <p className={Styles.text}>Coming Soon</p>
                <p className={Styles.logo}>Tasha Black</p>
                <p className={Styles.subHeading}>Leather</p>
            </div>
            <a className={Styles.shopBtn} href="https://forms.gle/qhkoQA5aWCU2AfZz5" 
            target="_blank" rel="noreferrer">Get Notified</a>
        </div>
    )
}