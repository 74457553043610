import Styles from '../Styles/popup.module.scss';
import { FunctionComponent } from "react";

type Props = {
    children?: React.ReactNode,
}

const Popup: FunctionComponent<Props> = ({ children }) => {
    return (
        <>
            <div className={Styles.popupOverlay} />
            <div className={Styles.popup}>
                {children}
            </div>
        </>
    );
}

export default Popup;