import Styles from '../Styles/main.module.scss'
import Grid from '../Components/grid'

export default function Home() {
  let root = document.documentElement;
  root.style.setProperty('--Primary', '#000');

  return (
    <div className={Styles.container}>
      <Grid />
    </div>
  )
}
