import Styles from "./Styles/main.module.scss";
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Home from "./Pages/Home";
import Shop from "./Pages/Shop";
import AgeConsent from "./Components/ageconsent";
import Footer from "./Components/footer";
import icon from "./Assets/icon.png";
import Loader from "react-spinners/ScaleLoader";

export const App = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1500)
  }, [])

  let root = document.documentElement;

  return (
    <BrowserRouter>
      {loading ? (
        <div className={Styles.loader}>
          <Loader color={'lightblue'} height={38} width={12} />
        </div>
      ) : (
        <>
          <Helmet>
            <link rel="icon" href={icon} />
          </Helmet>
          <AgeConsent />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Shop" element={<Shop />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </>
      )}
    </BrowserRouter>
  )
}