// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WgMTiMk2mBzix8TqW0z-3A\\=\\={position:absolute;top:0;right:0;bottom:0;left:0;background:rgba(126,126,126,.2);-webkit-backdrop-filter:blur(3px);backdrop-filter:blur(3px);z-index:1}._3ImEVcdqTwSisqOjakX9NA\\=\\={position:fixed;background:#fff;border:4px solid #389cf4;padding:8rem;z-index:2}@media screen and (max-width: 60rem){._3ImEVcdqTwSisqOjakX9NA\\=\\={padding:4rem}}@media screen and (max-width: 30rem){._3ImEVcdqTwSisqOjakX9NA\\=\\={width:65%;padding:0 2rem}}", "",{"version":3,"sources":["webpack://./src/Styles/popup.module.scss"],"names":[],"mappings":"AAAA,4BACI,iBAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA,CACA,+BAAA,CACA,iCAAA,CACQ,yBAAA,CACR,SAAA,CAGJ,6BACI,cAAA,CACA,eAAA,CACA,wBAAA,CACA,YAAA,CACA,SAAA,CAGJ,qCACI,6BACI,YAAA,CAAA,CAIR,qCACI,6BACI,SAAA,CACA,cAAA,CAAA","sourcesContent":[".popupOverlay {\r\n    position: absolute;\r\n    top: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    left: 0;\r\n    background: rgba(126, 126, 126, 0.2);\r\n    -webkit-backdrop-filter: blur(3px);\r\n            backdrop-filter: blur(3px);\r\n    z-index: 1;\r\n}\r\n\r\n.popup {\r\n    position: fixed;\r\n    background: white;\r\n    border: 4px solid #389cf4;\r\n    padding: 8rem;\r\n    z-index: 2;\r\n}\r\n\r\n@media screen and (max-width: 60rem) {\r\n    .popup {\r\n        padding: 4rem;\r\n    }\r\n}\r\n\r\n@media screen and (max-width: 30rem) {\r\n    .popup {\r\n        width: 65%;\r\n        padding: 0 2rem;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupOverlay": "WgMTiMk2mBzix8TqW0z-3A==",
	"popup": "_3ImEVcdqTwSisqOjakX9NA=="
};
export default ___CSS_LOADER_EXPORT___;
